export default function useFormFields() {
  return {
    username: {
      control: 'text-input',
      name: 'username',
      placeholder: 'Provide your username',
      type: 'text',
      mt: 'lg',
      col: {
        md: 12,
        lg: 12,
      },
    },
    password: {
      control: 'password-input',
      name: 'password',
      placeholder: 'Provide your password',
      mt: 'lg',
      col: {
        md: 12,
        lg: 12,
      },
    },
  };
}
