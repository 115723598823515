'use client';
export const dynamic = 'force-dynamic';

import { LoginForm } from '@/components/Auth/LoginForm';
import React, { useContext, useEffect } from 'react';
import AuthContext from '@/context/AuthContext';
import { useRouter } from 'next/navigation';
import { ROUTE_DRIVE_LIST } from '@/utils/Constants';

const Login = () => {
  const router = useRouter();
  const { isLoggedIn } = useContext(AuthContext);
  const isUserLoggedIn: any = isLoggedIn();

  useEffect(() => {
    if (isUserLoggedIn) {
      setTimeout(() => {
        router.push(ROUTE_DRIVE_LIST);
      }, 1000);
    }
  }, [isUserLoggedIn, router]);
  return <LoginForm />;
};

export default Login;
