import { Paper, Button, Text, Switch, Group, Anchor } from '@mantine/core';

import useFormFields from './useFormFields';
import FormController from '../Form/Controller';
import { useForm as useHookForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { forgetPasswordSchema, loginSchema } from '@/context/validationSchema';
import { BrandLogo } from '../BrandLogo';
import AuthContext from '@/context/AuthContext';
import { useContext, useState } from 'react';
export function LoginForm() {
  type LoginFormProps = {
    username: string;
    password: string;
    remember?: boolean;
  };

  const [formState, setFormState] = useState(1);

  const formElements = useHookForm<LoginFormProps>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const formResetElements = useHookForm<LoginFormProps>({
    resolver: zodResolver(forgetPasswordSchema),
    defaultValues: {
      username: '',
    },
  });

  const [isRemember, setIsRemember] = useState(false);
  const { login, isLoading, forgetPassword } = useContext(AuthContext);

  // login implementation
  const onSubmit = ({ username, password }: LoginFormProps) => {
    login({ username, password, remember: isRemember });
  };

  //forget password implementation
  const forgetPasswordSubmit = ({ username }: LoginFormProps) => {
    forgetPassword({ username });
    setTimeout(() => {
      setFormState(1);
    }, 2000);
  };

  const { username, password }: any = useFormFields();
  return (
    <Paper withBorder shadow="md" mt={20} p={45} radius="md">
      <BrandLogo maw={240} mx="auto" radius="md" mb="lg" />
      <Text size="sm" align="center">
        Please enter your user information
      </Text>
      {formState == 1 && (
        <FormProvider {...formElements}>
          <form onSubmit={formElements.handleSubmit(onSubmit)}>
            <FormController {...username} />
            <FormController {...password} />
            <Group position="apart" mt="md">
              {/* <Switch
                onLabel="YES"
                offLabel="NO"
                size="md"
                label="Remember me"
                name="remember"
                checked={isRemember}
                onChange={() => setIsRemember(!isRemember)}
              /> */}
              <Anchor component="button" onClick={() => setFormState(2)} size="sm">
                Forgot password?
              </Anchor>
            </Group>

            <Button type="submit" mt="xl" loading={isLoading} fullWidth>
              {formElements.formState.isSubmitting ? 'Signing' : 'Sign me in'}
            </Button>
          </form>
        </FormProvider>
      )}
      {formState == 2 && (
        <FormProvider {...formResetElements}>
          <form onSubmit={formResetElements.handleSubmit(forgetPasswordSubmit)}>
            <FormController {...username} />
            <Group position="apart" mt="md">
              <Anchor component="button" size="sm" onClick={() => setFormState(1)}>
                Have an Account?
              </Anchor>
            </Group>

            <Button type="submit" mt="xl" loading={isLoading} fullWidth>
              {formElements.formState.isSubmitting ? 'Submitting' : 'Submit'}
            </Button>
          </form>
        </FormProvider>
      )}
    </Paper>
  );
}
