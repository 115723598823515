import React from 'react';
import { Image } from '@mantine/core';
import { BrandLogoProps } from '@/types/Form';

const BrandLogo = (props: BrandLogoProps) => {
  const { maw = 240, style = {}, mx = 'auto', radius = 'md', mb = 'lg' } = props;

  return (
    <Image
      src="/img/logo_small.png"
      alt="brand-logo"
      style={style}
      maw={maw}
      mx={mx}
      radius={radius}
      mb={mb}
    />
  );
};

export default BrandLogo;
